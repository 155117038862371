<p-confirmDialog #cd [baseZIndex]="10002" [closable]="false">
  <p-footer>
    <button class="btn btn-primary" (click)="cd.reject()">
      {{ sitecoreTemplate?.No || "no" }}
    </button>
    <button class="btn btn-primary" (click)="cd.accept()">
      {{ sitecoreTemplate?.Yes || "yes" }}
    </button>
  </p-footer>
</p-confirmDialog>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
  <div class="container">
    <div class="h1" id="UserProfileTitle">
      {{ sitecoreTemplate?.UserProfileTitle || "update Login Settings" }}
    </div>

    <div class="alert alert-info">
      <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
      <span
        [innerHTML]="
          sitecoreTemplate?.UserProfileInfo ||
          'you may update any or all account login credentials.  Changes you make here will affect your ability to log in.'
        "
      >
      </span>
    </div>

    <div *ngIf="successMessage" class="alert alert-success">
      <i class="fa fa-check fa-lg" aria-hidden="true"></i> &nbsp;
      {{ successMessage }}
    </div>
    <div *ngIf="errorMessage" class="alert alert-error">
      <i class="fa fa-ban fa-lg" aria-hidden="true"></i> &nbsp;
      {{ errorMessage }}
    </div>
    <div *ngIf="f.invalid && f.touched" class="alert alert-error">
      <i class="fa fa-ban fa-lg" aria-hidden="true"></i> &nbsp;
      {{
        sitecoreTemplate?.ValidationErrorMessage ||
          "please enter all required fields."
      }}
    </div>

    <h2>
      {{ sitecoreTemplate?.ContactAndSecurityLabel || "contact & Security" }}
    </h2>
    <hr />

    <br />
    <h3>{{ sitecoreTemplate?.MemberDetailsLabel || "member Details" }}</h3>
    <div class="group-container">
      <p>{{ memberProfile?.LastName }}, {{ memberProfile?.FirstName }}</p>
      <div>
        {{ sitecoreTemplate?.UserIDLabel || "user ID" }}:
        {{ userProfile.userID }}
      </div>
    </div>

    <br />
    <h3>
      {{
        sitecoreTemplate?.AccountRecoveryMethodsLabel ||
          "account Recovery Methods"
      }}
    </h3>

    <div class="group-container">
      <div class="row">
        <div class="col-md-6">
          <label for="contactPhoneNumber" class="col-form-label">
            {{ sitecoreTemplate?.ContactPhoneLabel || "contact number" }}*
          </label>
          <a class="float-right pt-2" href="/#/updatememberprofile">{{
            sitecoreTemplate?.UpdateMemberProfileText ||
              "update Personal Information"
          }}</a>
          <input
            id="contactPhoneNumber"
            name="contactPhoneNumber"
            placeholder="___ - ___ - ____"
            [ngModel]="userProfile.mobilePhone | phone"
            class="form-control"
            maxlength="12"
            #contactPhone="ngModel"
            pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
            [forbiddenNumbers]
            [ngClass]="{
              'is-invalid':
                (contactPhone.hasError('forbiddenPhone') ||
                  contactPhone.invalid) &&
                contactPhone.touched
            }"
            (ngModelChange)="onPhoneChange($event)"
            phone
          />

          <label
            *ngIf="contactPhone.hasError('required') && contactPhone.touched"
            class="invalid-feedback"
          >
            {{
              sitecoreTemplate?.MobilePhoneRequiredMessage ||
                "phone number is required."
            }}
          </label>
          <label
            *ngIf="
              (contactPhone.hasError('forbiddenPhone') ||
                contactPhone.hasError('pattern')) &&
              contactPhone.touched
            "
            class="invalid-feedback"
          >
            {{
              sitecoreTemplate?.MobilePhoneInvalidInputMessage ||
                "phone number is invalid."
            }}
          </label>
        </div>

        <div class="col-md-6">
          <label for="contactEmail" class="col-form-label">
            {{ sitecoreTemplate?.EmailLabel || "email" }}
          </label>
          <input
            id="contactEmail"
            name="contactEmail"
            placeholder=""
            [(ngModel)]="userProfile.email"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                (contactEmail.invalid || contactEmail.length === 0) &&
                contactEmail.touched &&
                contactEmail.dirty
            }"
            #contactEmail="ngModel"
            required
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            email
          />

          <label
            *ngIf="contactEmail.hasError('required') && contactEmail.touched"
            class="invalid-feedback"
          >
            {{
              sitecoreTemplate?.EmailRequiredErrorMessage || "email is required"
            }}
          </label>
          <label
            *ngIf="contactEmail.hasError('pattern') && contactEmail.touched"
            class="invalid-feedback"
          >
            {{
              sitecoreTemplate?.InvalidEmailFormatMessage || "email is invalid."
            }}
          </label>
        </div>
      </div>
    </div>

    <br />
    <h3 ID="securityQuestionsLabel">
      {{ sitecoreTemplate?.SecurityQuestionsLabel || "security questions" }}
    </h3>
    <div class="group-container">
      <app-security-question
        name="securityQuestion"
        id="securityQuestion"
        #securityQuestions
        [securityQuestionProperties]="securityQuestionProperties"
        [(ngModel)]="kba"
      >
      </app-security-question>
    </div>

    <!-- submit -->
    <div class="row justify-content-end mt-5 mb-5">
      <button
        class="btn btn-outline-primary col-md-3 m-2"
        type="button"
        id="btnCancel"
        (click)="confirmFormReset(f)"
      >
        {{ sitecoreTemplate?.CancelLabel || "cancel" }}
      </button>

      <button class="btn btn-primary col-md-3 m-2" type="submit" id="btnFinish">
        {{ sitecoreTemplate?.FinishLabel || "finish" }}
      </button>
    </div>

    <!-- BEGIN Update Password-->
    <h2>{{ sitecoreTemplate?.UpdatePasswordLabel || "password" }}</h2>
    <hr />

    <br />
    <h3 ID="resetPasswordLabel">
      {{ sitecoreTemplate?.ResetPasswordLabel || "reset Password" }}
    </h3>
    <div class="group-container">
      <app-password
        name="password"
        id="password"
        [passwordProperties]="passwordProperties"
        (emitPassword)="onPasswordChange($event)"
        [userName]="userProfile.userID"
      >
      </app-password>
    </div>

    <!-- submit -->
    <div class="row justify-content-end mt-5 mb-5">
      <button
        class="btn btn-outline-primary col-md-3 m-2"
        type="button"
        id="btnPasswordCancel"
        (click)="onPasswordCancel(f)"
      >
        {{ sitecoreTemplate?.CancelLabel || "cancel" }}
      </button>

      <button
        class="btn btn-primary col-md-3 m-2"
        type="button"
        id="btnPasswordUpdate"
        (click)="onPasswordUpdate()"
        [disabled]="!isValidPassword"
      >
        {{ sitecoreTemplate?.UpdatePasswordButton || "update Password" }}
      </button>
    </div>
    <!-- END Update Password-->
  </div>
</form>
