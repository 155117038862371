<div class="navbar navbar-expand-lg navbar-light">

  <a class="navbar-brand" href="#" id="logo-area">
    <picture>
      <source srcset="assets/images/logo_cal.png" media="(max-width: 991px)">
      <source srcset="assets/images/logo_cal.png">
      <img src="assets/images/logo_cal.png" class="img-fluid" alt="CalOptima Health">
    </picture>
  </a>

  <div class="navbar-custom navbar-collapse justify-content-end lang-menu-white">
    <app-language></app-language>
  </div>
</div>
